import { baseURL } from '@/config'
import axios from 'axios'
import dayjs from 'dayjs'
import PDFMerger from 'pdf-merger-js/browser'

export type UseMergePdfType = {
  mergePdf: (date: string[], title: string) => File | any;
};

export const useMergePdf = (): UseMergePdfType => {
	const mergePdf = async (pathList: string[], title: string) => {
		try {
			const merger = new PDFMerger()
			for (const url of pathList) {
				const res = await axios
					.get(url, {
						// eslint-disable-next-line indent
            baseURL,
						responseType: 'blob',
					})
					.then((res) => res.data)
				const blob = new Blob([res], { type: 'application/pdf' })
				await merger.add(blob)
			}
			return await merger.save(
				`${dayjs().format('YYYY-MM-DD : HH:mm')}-ShippingLabel${title}`
			)
		} catch (error) {
			console.log('merge PDF error', error)
		}
	}
	return {
		mergePdf,
	}
}
