import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ORDER_PATH } from '@/constants/route'
import { getOrderPrintById, marketplaceLabel } from '@/api/print/print.service'
import { ElNotification } from 'element-plus'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useShippingStore } from '@/pinia/shipping/shipping.pinia'
import { useAnalytics } from '@/use/useAnalytics'
import { EVENT_NAME } from '@/constants/analytics'
import { usePrint } from '@/use/usePrint'
import { useMergePdf } from '@/use/useMergePdf'
import { IOrderPrintList, IMarketplaceLabelList } from '@/type/print'
import { MARKETPLACE_NAME } from '@/constants/marketPlaceEnum'
export type UserShippingType = {
    onDownloadPrintMarketplace: (date: string[],title:string) => File | any,
}
export const userShipping = ():any => {
	const router = useRouter()
	const orderPrintList = ref<Array<IOrderPrintList>>([])
	const isChangeValueOrder = ref<boolean>(false)
	const shippingStore = useShippingStore()
	const { onUpdatePrint } = usePrint()
	const { logEvent } = useAnalytics()
	const { mergePdf } = useMergePdf()
	const shopStore = useShopStore()
	const currentShop = computed(() => shopStore.currentShop)
	const txtWarning = ref<string>('กรุณาเลือกรายการออเดอร์ที่ต้องการจะพิมพ์')
	const isWarning = ref<boolean>(false)
	const orderID = computed(() => shippingStore.getterOrderListId)
	const orderList = computed(() => shippingStore.getterOrderList)
	const isLoadingPDF = ref<boolean>(false)
	const shippingTypeActive = ref<string>('')
	const labelMarketplaceList = ref<IMarketplaceLabelList[]>([
		{
			name:MARKETPLACE_NAME.TIKTOK,
			list:[
				{
					title: 'A5',
					image: require('@/assets/print/paper/default-1-4.png'),
					value: 'A5'
				},
				{
					title: 'A6',
					image: require('@/assets/print/paper/default-1-6.png'),
					value: 'A6'
				},
			]
		},
		{
			name:MARKETPLACE_NAME.SHOPEE,
			list:[
				{
					title: 'A6',
					image: require('@/assets/print/paper/default-1-6.png'),
					value: 0
				},
			]
		},
		{
			name:MARKETPLACE_NAME.LAZADA,
			list:[
				{
					title: 'A6',
					image: require('@/assets/print/paper/default-1-6.png'),
					value: 0
				},
			]
		}
	])
	const onGetOrderList = async () => {
		try {
			isLoadingPDF.value = true
			shippingStore.updateOrderList([])
			const params = {
				orders: orderID.value
			}
			const res = await getOrderPrintById(params)
			orderPrintList.value = res
			isChangeValueOrder.value = true
			shippingStore.updateOrderList(orderPrintList.value)
			isLoadingPDF.value = false
		} catch (error) {
			isLoadingPDF.value = false
			if(orderID.value.length === 0) {
				ElNotification({
					title: 'แจ้งเตือน',
					message: 'กรุณาเลือกรายการออเดอร์ที่ต้องการจะพิมพ์',
					type: 'warning'
				})
				router.push({path: ORDER_PATH})
			} else {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'ระบบเกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
					type: 'error'
				})
			}
		}
	}
	const onDownloadPrintMarketplace = async (label:{ title:string, value:number },marketplaceType:string) => {
		isLoadingPDF.value = true
		const payload = {
			marketplace:marketplaceType,
			orderList:orderList.value,
			shopId:currentShop.value?._id,
			documentSize:label.value,
		}
		try {
			const { data: res } = await marketplaceLabel(payload)
			if (res) {
				await mergePdf(res,label.title)
				await onUpdatePrint(orderID.value)
			}
		} catch (error) {
			console.log('error',error)
		}
		isLoadingPDF.value = false
		// logs
		logEvent({ eventName: EVENT_NAME.selectPrintShippingSize, eventPayload: { payload } })
	}
	const onPrintSuccess = () => {
		shippingTypeActive.value = ''
	}
	const onDownloadPrint = async (type: string) => {
		isLoadingPDF.value = true
		await onUpdatePrint(orderID.value)
		shippingTypeActive.value = type
		isLoadingPDF.value = false
		// logs
		logEvent({ eventName: EVENT_NAME.selectPrintShippingSize, eventPayload: { type } })
	}
	return {
		orderPrintList,
		onDownloadPrintMarketplace,
		onGetOrderList,
		onDownloadPrint,
		onPrintSuccess,
		orderID,
		txtWarning,
		isWarning,
		labelMarketplaceList,
		isLoadingPDF,
		shippingTypeActive
	}
}